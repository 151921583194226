import { Grid, TextField } from '@material-ui/core';
import { FunctionComponent } from 'react';
import FormRow from '../../../../../../../shared/component/formFields/FormRow';
import { AgentDescriptionMailProps } from '../descriptionModels';

const Mail: FunctionComponent<AgentDescriptionMailProps> = ({
    formData,
    lookups,
    setFormDataToPersist,
    renderField,
    renderDropDown,
    handleFloatChange,
    isAdmin,
}) => {
    const shouldDisableField = () => {
        const enable = isAdmin || formData.tridentId <= 0;
        return !enable;
    };

    return (
        <div className="section">
            <Grid
                item
                container
                sm={12}
                md={12}
                alignItems="center"
                className="content"
                spacing={2}
            >
                {renderField('ADDRESS 1*', 'address1', shouldDisableField())}
                {renderField('ADDRESS 2', 'address2', shouldDisableField())}
            </Grid>
            <Grid
                item
                container
                sm={12}
                md={12}
                alignItems="center"
                className="content"
                spacing={2}
            >
                {renderField('CITY*', 'city', shouldDisableField())}
                {renderDropDown(
                    'STATE*',
                    'regionId',
                    lookups.states,
                    shouldDisableField(),
                )}
            </Grid>
            <Grid
                item
                container
                sm={12}
                md={12}
                alignItems="center"
                className="content"
                spacing={2}
            >
                <FormRow
                    label="ZIP*"
                    control={
                        <TextField
                            InputProps={{
                                disableUnderline: true,
                                className: shouldDisableField()
                                    ? 'inputBaseRoot inputDisabled'
                                    : 'inputBaseRoot',
                                'aria-label': 'zip',
                            }}
                            inputProps={{ maxLength: 5 }}
                            value={formData.zip ? formData.zip : ''}
                            onChange={handleFloatChange}
                            onBlur={setFormDataToPersist}
                            name="zip"
                            disabled={shouldDisableField()}
                        />
                    }
                    prefix=""
                    suffix=""
                    controlClass="formControlRoot"
                    labelXs={null}
                    labelSm={null}
                    labelMd={2}
                    controlXs={null}
                    controlSm={null}
                    controlMd={4}
                />
            </Grid>
        </div>
    );
};

export default Mail;
