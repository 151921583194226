import { Grid } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { AgentDescriptionPhoneProps } from '../descriptionModels';

const Phone: FunctionComponent<AgentDescriptionPhoneProps> = ({
    formData,
    renderField,
    isAdmin,
}) => {
    return (
        <div className="section">
            <Grid
                item
                container
                sm={12}
                md={12}
                alignItems="center"
                className="content"
                spacing={2}
            >
                {renderField(
                    'WORK PHONE*',
                    'workPhone',
                    !isAdmin && formData.workPhone !== '',
                    true,
                )}
                {renderField(
                    'MOBILE PHONE',
                    'mobilePhone',
                    !isAdmin && formData.mobilePhone !== '',
                    true,
                )}
            </Grid>
            <Grid
                item
                container
                sm={12}
                md={12}
                alignItems="center"
                className="content"
                spacing={2}
            >
                {renderField('HOME PHONE', 'homePhone', false, true)}
                {renderField('FAX', 'fax', !isAdmin && formData.fax !== '', true)}
            </Grid>
        </div>
    );
};

export default Phone;
