import { SectionIdToName, OverViewConstantsModel } from './cmrReportModel';

export const SectionIdToNameMapping: SectionIdToName = {
    viewsOnWeb: 1,
    inTheNews: 2,
    advertisements: 3,
    showings: 4,
    openHouses: 5,
    emailCampaigns: 6,
    socialCampaigns: 7,
    featuredPlacements: 8,
    additionalPromotion: 9,
    brokersLetter: 10,
    graph: 11,
    homePage: 99,
    overView: 98,
};

export const OverViewConstants: OverViewConstantsModel = {
    1: {
        contentOne:
            'Since [listed date] your property has been viewed [x] time[s] across our syndication websites. This includes websites such as Sothebysrealty.com, Zillow.com, Realtor.com, etc.',
        contentTwo: '',
    },
    2: {
        contentOne:
            'Since [listed date] your property has been featured in the news [x] time[s]. Placements in the news are PR exposure opportunities exclusive to Sotheby’s International Realty.',
        contentTwo: '',
    },
    3: {
        contentOne:
            'Since [listed date], your property has been selected for inclusion in [x] advertisement[s].',
        contentTwo: '',
    },
    4: {
        contentOne:
            'Since [listed date], [x] private showing[s] [a] been held. Showings are private tours of your property to qualified buyers and other real estate agents.',
        contentTwo: '',
    },
    5: {
        contentOne:
            'Since [listed date], [x] open house[s] [a] been scheduled to showcase your property.',
        contentTwo: '',
    },
    6: {
        // do not change the spacing between the two text, split is used in overview report to show the text based on condition
        contentOne:
            'Since [listed date], your property has been featured in [x] email campaign[s]. These campaigns were sent to [r] recipients and generated [v] views and [i] interactions.',
        contentTwo:
            'Your property was featured in an email campaign on [date]. The campaign was sent to [r] recipients and generated [v] views and [i] interactions.',
    },
    7: {
        contentOne:
            'Since [listed date], your property has been featured in [x] social campaign[s]. Social campaigns are paid advertisements of your property on social media channels.',
        contentTwo: '',
    },
    8: {
        contentOne:
            'Since [listed date] your property has been featured in [x] special promotion[s]. Featured placements are opportunities intended to maximize exposure for your property and can include placement on Sothebysrealty.com and other luxury websites, and posts to Sotheby’s International Realty social media channels.',
        contentTwo: '',
    },
    9: {
        contentOne:
            'Additional promotion includes various other channels we used to market and promote your listing.',
        contentTwo: '',
    },
};
