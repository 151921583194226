import { postCallRaw, getCall, postCall } from '../../utils/api/apiWrapper';
import {
    ReportingRequest,
    CITReportForm,
    AgentCitReportResults,
    AdvertisementMarketingRequest,
    AdvertisementMarketingReportResults,
    ABCRReportRequest,
    ABCRReportDetails,
} from './reportingModel';

export function exportReportsApi(
    request: ReportingRequest,
    from: string,
): Promise<Response> {
    let url = `agent/websiteReport`;
    if (from === 'showcase') {
        url = `showcaseSite/websiteReport`;
    }
    return postCallRaw<ReportingRequest>(url, request);
}

export function getAgentCitReport(
    request: CITReportForm,
): Promise<AgentCitReportResults[]> {
    return getCall<AgentCitReportResults[]>(
        `agent/${request.agentId}/citReport?startDate=${request.startDate}&endDate=${request.endDate}&includeMarketing=${request.includeMarketing}&includeAds=${request.includeAds}`,
    );
}

export function getAdMarketingReport(
    agentId: string,
    request: AdvertisementMarketingRequest,
): Promise<AdvertisementMarketingReportResults[]> {
    return postCall<AdvertisementMarketingRequest, AdvertisementMarketingReportResults[]>(
        `agent/${agentId}/advertisingReport`,
        request,
    );
}

export function exportCitReport(request: CITReportForm): Promise<Response> {
    return postCallRaw<CITReportForm>(
        `agent/${request.agentId}/citReportExport?startDate=${request.startDate}&endDate=${request.endDate}&includeMarketing=${request.includeMarketing}&includeAds=${request.includeAds}`,
        request,
    );
}

export function exportABCRReport(data: ABCRReportRequest): Promise<Response> {
    const url = 'listing/abcrexport';
    return postCallRaw(url, data);
}

export function getABCRReportDetails(
    data: ABCRReportRequest,
): Promise<ABCRReportDetails> {
    const url = 'listing/abcrdetails';
    return postCall<ABCRReportRequest, ABCRReportDetails>(url, data);
}

export function exportABCRReportListingDetails(
    data: ABCRReportRequest,
): Promise<Response> {
    const url = 'listing/abcrlistingdetails';
    return postCallRaw(url, data);
}
